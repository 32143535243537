import C from "classnames";
import { graphql } from "gatsby";
import React, { useMemo } from "react";
import page from "..";
import style from "./index.module.styl";
import GeneralLayout from "../../layouts/general";
import { ROUTES } from "../../../common/routes.js";
import { ICollection } from "shared/lib/models/collection";
import { useGlobalStore } from "../../../store";
import { ITherapy } from "shared/lib/models/therapy";
import Header from "../../header";
import { IInstallationTypeMapping } from "shared/lib/models/installationTypeMapping";
import { IModel } from "shared/lib/models/model";
import { IFluidImage } from "../../../common/gatsby";
import { getMinimum } from "../../../utils/lists";
import SEO from "../../../components/seo";
import FilteredGrid, {
  IFilterGroup,
  IFilter,
  IFilterItems,
  FilterType,
  IImageForFilter,
} from "../../FilteredGrid";
import { InstallationTypes } from "../../../../../shared/src/models/installationTypeMapping";

type CollectionsPageCollection = Omit<ICollection, "image"> & {
  readonly image: IFluidImage;
  readonly alcoveModelImage: IFluidImage;
  readonly dropInModelImage: IFluidImage;
  readonly freestandingModelImage: IFluidImage;
};

interface ICollectionsProps {
  readonly data: {
    readonly allStrapiInstallationTypeMappings: {
      readonly nodes: IInstallationTypeMapping[];
    };
    readonly allStrapiModels: {
      readonly nodes: IModel[];
    };
    readonly allStrapiCollections: {
      readonly nodes: CollectionsPageCollection[];
    };
    readonly allStrapiTherapies: {
      readonly nodes: ITherapy[];
    };
  };
}

function Collections(props: ICollectionsProps) {
  const {
    makeLink,
    i18n,
    breadcrumbs,
    therapyHelpers: { getTherapyLabel },
  } = useGlobalStore();

  const therapies: ITherapy[] = props.data.allStrapiTherapies.nodes;
  const collections: ICollection[] = props.data.allStrapiCollections.nodes;
  const models: IModel[] = props.data.allStrapiModels.nodes;

  const installationTypesMappings =
    props.data.allStrapiInstallationTypeMappings.nodes;

  const mappedFilters = (therapies: ITherapy[], installationTypes: any) => {
    const therapyFilters: IFilter[] = therapies.map((therapy: ITherapy) => {
      return {
        id: therapy.id.toString(),
        filterType: FilterType.Therapy,
        checked: false,
        text: () => getTherapyLabel(therapy),
      };
    });
    const installationTypeFilters: IFilter[] = installationTypes.map(
      (installationType: any) => {
        return {
          id: (
            installationType.installation_type_mapping ?? installationType.id
          ).toString(),
          filterType: FilterType.InstallationType,
          checked: false,
          type: installationType.type,
          text: (i18n: any) =>
            i18n.tObj(installationType.title, installationType.title_fr),
        };
      },
    );

    return [...therapyFilters, ...installationTypeFilters];
  };

  const filters = mappedFilters(therapies, installationTypesMappings);
  const mappedCollections = collections.map(collection => {
    const _models = collection.models.map(
      x => models.find(y => x.id === y.id) as IModel,
    );

    const minPrice = getMinimum(_models, model => model.tubPrice);

    const minTherapyPrice = getMinimum(
      _models.map(model => {
        const _therapies = therapies;
        const prices = _therapies.map(therapy =>
          therapy.isTMU ? model.tmuPrice : model.tubPrice + therapy.price,
        );

        return getMinimum(prices, price => price);
      }),
      x => x,
    );
    // TODO: The IDs used in the following block will only work when the installation types have only been imported once.
    // We need to replace this solution with something more robust that will still work after deleting a reimporting data.
    const imagesForFilter: IImageForFilter[] = [];
    if (collection.alcoveModelImage) {
      imagesForFilter.push({
        type: InstallationTypes.alcove,
        image: collection.alcoveModelImage,
      });
    }
    if (collection.dropInModelImage) {
      imagesForFilter.push({
        type: InstallationTypes.dropin,
        image: collection.dropInModelImage,
      });
    }
    if (collection.freestandingModelImage) {
      imagesForFilter.push({
        type: InstallationTypes.freestanding,
        image: collection.freestandingModelImage,
      });
    }

    return {
      collection,
      minPrice,
      minTherapyPrice,
      imagesForFilter,
    };
  });

  const collectionGridItems: IFilterItems[] = useMemo(
    () =>
      mappedCollections.map(collection => {
        return {
          defaultImage: collection.collection.image,
          imagesForFilter: collection.imagesForFilter,
          name: collection.collection.name,
          price: i18n.t("collections_from_price", {
            price: collection.minTherapyPrice || collection.minPrice || 0,
          }),
          href: makeLink(ROUTES.collection(collection.collection.name)),
          filtersTypes: mappedFilters(
            collection.collection.therapies,
            collection.collection.installationTypes,
          ),
        };
      }),
    [mappedCollections, i18n],
  );

  const filterFieldsList: Array<IFilterGroup> = [
    {
      title: i18n.t("collections_installation_type_filter"),
      filterType: FilterType.InstallationType,
      filters: filters.filter(
        filter => filter.filterType === FilterType.InstallationType,
      ),
    },
    {
      title: i18n.t("collections_therapy_filter"),
      filterType: FilterType.Therapy,
      filters: filters.filter(
        filter => filter.filterType === FilterType.Therapy,
      ),
    },
  ];

  const pageTitle = `${i18n.t("meta_title_prefix")} - ${i18n.t(
    "meta_collections_title",
  )}`;

  return (
    <GeneralLayout breadcrumbs={breadcrumbs}>
      <SEO path={ROUTES.collections()} title={pageTitle} />
      <div className={C(style.collectionsPage)}>
        <div className={style.collectionsPageContainer}>
          <div className={style.collectionsPageTop}>
            <Header
              titleDesktop={i18n.t("collections_title")}
              titleMobile={i18n.t("collections_title")}
            />
          </div>

          <FilteredGrid
            items={collectionGridItems}
            filterGroups={filterFieldsList}
            btnText={i18n.t("collections_collection_btn")}
          />
        </div>
      </div>
    </GeneralLayout>
  );
}

export default page(Collections);

export const query = graphql`
  query CollectionsTemplate {
    allStrapiInstallationTypeMappings {
      nodes {
        id: strapiId
        name
        title
        title_fr
        type
        installationTypes: installation_types {
          id
        }
      }
    }
    allStrapiModels {
      nodes {
        id: strapiId
        tubPrice
        tmuPrice
        therapies {
          id
        }
      }
    }
    allStrapiCollections {
      nodes {
        id: strapiId
        name
        alcoveModelImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        dropInModelImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        freestandingModelImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        installationTypes: installation_types {
          id
          name
          installation_type_mapping
        }
        therapies {
          id
          name
        }
        models {
          id
          name
          tubPrice
        }
      }
    }
    allStrapiTherapies {
      nodes {
        id: strapiId
        category
        isTMU
        price
      }
    }
  }
`;
