import { IBase } from "./base";
import { IInstallationType } from "./installationType";

export enum InstallationTypes {
  alcove = "alcove",
  freestanding = "freestanding",
  dropin = "dropin",
}

export interface IInstallationTypeMapping extends IBase {
  readonly name: string;
  readonly title: string;
  readonly title_fr: string;
  readonly image: any;
  readonly installationTypes: IInstallationType[];
  readonly type: InstallationTypes;
}
